export interface IStore {
    Auth: IAuthReducer,
    Reload: IReloadReducer,
}

// AUTH
export const SET_AUTH = 'SET_AUTH'

export interface IAuthReducer {
    isAuth: boolean
}

export interface ISetAuthAction {
    type: typeof SET_AUTH
    payload: boolean
}

// RELOAD
export const SET_RELOAD_LIST = 'SET_RELOAD_LIST'
export const SET_RELOAD_USER = 'SET_RELOAD_USER'

export interface IReloadReducer {
    list: number,
    user: number,
}

export interface ISetReloadListAction {
    type: typeof SET_RELOAD_LIST
}
export interface ISetReloadUserAction {
    type: typeof SET_RELOAD_USER
}
