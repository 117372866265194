import React, {Fragment} from "react";
import {BFNotificationsMatchUrlEntity} from "../../../../../db";

interface Props {
    matches: BFNotificationsMatchUrlEntity[],
    removeMatchUrl: (val: number) => void
}

const types = {
    simple: "Simple",
    contains: "Contains",
    all: "All pages",
    '': ''
}

export default function MatchTable({matches, removeMatchUrl}: Props) {

    if (!matches || matches.length === 0) {
        return <Fragment/>;
    }

    return (
        <table className="table-list">
            <thead>
            <tr>
                <th>#</th>
                <th>Type</th>
                <th colSpan={2}>URL</th>
            </tr>
            </thead>
            <tbody>
            {matches.map((item: BFNotificationsMatchUrlEntity, index: number) => {
                const type: string = !!item.get('type') ? item.get('type') : '';
                const getType = () => {
                    // @ts-ignore
                    return types[type];
                }
                return (
                    <tr key={"match-" + index + "-" + item.id}>
                        <td>{index + 1}</td>
                        <td>{getType()}</td>
                        <td>{item.get('url')}</td>
                        <td>
                            <ul className="list-icon">

                                <li><a href="/remove" onClick={e => {
                                    e.preventDefault();
                                    removeMatchUrl(index);
                                }}><i className="icon-trash"></i> <span className="hidden">Remove</span></a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}