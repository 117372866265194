import React, {Fragment} from "react";
import StepSubmit from "./StepSubmit";
import {BFNotificationsEntity} from "../../../../db";
import FieldSwitch from "../../../../form/FieldSwitch";
import momentTZ from 'moment-timezone';
import StepFormWrapper from "./StepFormWrapper";
import Field from "../../../../form/Field";

interface Props {
    activeStep: number,
    setActiveStep: (val: number) => void,

    activeElement: BFNotificationsEntity,
    onDataPropChange: (val: any, key: string) => void,
}

const defaultTimeZone = momentTZ.tz.guess();
const timeZonesList = momentTZ.tz.names();

export default function Step4(props: Props) {
    const timeShowFlag = !!props.activeElement.get('timeShowFlag');
    const setTimeShowFlag = (val: boolean) => {
        props.onDataPropChange(val, 'timeShowFlag');
    }

    const allowCloseFlag = !!props.activeElement.get('allowCloseFlag');
    const setAllowCloseFlag = (val: boolean) => {
        props.onDataPropChange(val, 'allowCloseFlag');
    }

    const stopWhenTypingFlag = !!props.activeElement.get('stopWhenTypingFlag');
    const setStopWhenTypingFlag = (val: boolean) => {
        props.onDataPropChange(val, 'stopWhenTypingFlag');
    }

    const hideOnMobileFlag = !!props.activeElement.get('hideOnMobileFlag');
    const setHideOnMobileFlag = (val: boolean) => {
        props.onDataPropChange(val, 'hideOnMobileFlag');
    }

    const position = !!props.activeElement.get('position') ? props.activeElement.get('position') : 'bottom-left';

    const isShowSettings = [1, 2].indexOf(props.activeElement.getType()) === -1;
    const isShowRange = [1, 2].indexOf(props.activeElement.getType()) > -1;

    const doPreview = (e: React.MouseEvent) => {
        e.preventDefault();
        window.open('/preview/' + props.activeElement.id);
        // toast.success("Please wait for " + props.activeElement.getDelayFirstNotificationFor() + " seconds to first notification will appear");
        //
        // try {
        //     // @ts-ignore
        //     window.bfClass.stop();
        // } catch (e) {
        // }
        //
        // props.activeElement.save().then(() => {
        //     const bfScript = document.createElement("script");
        //     bfScript.src = props.activeElement.getScriptUrl();
        //     bfScript.type = "text/javascript";
        //     document.body.appendChild(bfScript);
        // });
    }

    const stopPreview = () => {

    }

    return <Fragment>
        <StepFormWrapper className="form-wide">
            <h2>Design</h2>
            <p>
                <label>Notification Position</label>
                <select value={position} onChange={(e) => props.onDataPropChange(e.target.value, 'position')}>
                    <option value="top-left">Top Left</option>
                    <option value="top-right">Top right</option>
                    <option value="bottom-left">Bottom Left</option>
                    <option value="bottom-right">Bottom right</option>
                </select>
            </p>
            {/*<p>*/}
            {/*    <label htmlFor="fwb">Description for chosen type</label>*/}
            {/*    <input type="text" id="fwb" name="fwb"*/}
            {/*           placeholder="Page URL / Expression / or no need to show this part"/>*/}
            {/*    <a href="./" className="btn">Add</a>*/}
            {/*</p>*/}

            <p>
                <span className="label">Hide on mobile screens</span>
                <FieldSwitch setVal={setHideOnMobileFlag} val={hideOnMobileFlag}/>
            </p>
            <p>
                <span className="label">Mobile position</span>
                <span className="input-check">
                    <span onClick={() => props.onDataPropChange('top', 'mobilePosition')}>
                        <input type="radio" onChange={() => {
                        }}
                               checked={props.activeElement.getMobilePosition() === 'top'}/>
                        <label>Top</label>
                    </span>
                    <span onClick={() => {
                        props.onDataPropChange('bottom', 'mobilePosition');
                    }}>
                        <input type="radio" onChange={() => {
                        }}
                               checked={props.activeElement.getMobilePosition() === 'bottom'}/>
                        <label>Bottom</label>
                    </span>
                </span>
            </p>
            <h2>Behavior</h2>
            <Field
                title={'Show first notification after'}
                fieldKey={'delayFirstNotificationFor'}
                val={props.activeElement.getDelayFirstNotificationFor()}
                onChange={
                    (key: string, val: string) => {
                        props.onDataPropChange(val, key)
                    }
                }
                placeholder={''}
                type={"number"}
            />
            <Field
                title={'Display notification for'}
                fieldKey={'displayEachNotificationFor'}
                val={props.activeElement.getDisplayEachNotificationFor()}
                onChange={
                    (key: string, val: string) => {
                        props.onDataPropChange(val, key)
                    }
                }
                placeholder={''}
                type={"number"}
            />
            {isShowSettings &&
            <Field
                title={'Space each notification for'}
                fieldKey={'spaceEachNotification'}
                val={props.activeElement.getSpaceEachNotification()}
                onChange={
                    (key: string, val: string) => {
                        props.onDataPropChange(val, key)
                    }
                }
                placeholder={''}
                type={"number"}/>
            }
            {isShowSettings &&
            <Field
                title={'Max notification on page'}
                fieldKey={'maxNotificationOnPage'}
                val={props.activeElement.getMaxNotificationOnPage()}
                onChange={
                    (key: string, val: string) => {
                        props.onDataPropChange(val, key)
                    }
                }
                placeholder={''}
                type={"number"}
            />
            }
            {isShowRange && <p>
                <span className="label">NumberRange</span>
                <span className="input-time">
                    <input type="number" value={props.activeElement.get('rangeFrom')}
                           onChange={e => props.onDataPropChange(e.target.value, 'rangeFrom')}/>
                    <span>-</span>
                    <input type="number" value={props.activeElement.get('rangeTo')}
                           onChange={e => props.onDataPropChange(e.target.value, 'rangeTo')}/>
                </span>
            </p>}
            <p className={"f-disabled"} title={"Coming soon"}>
                <span className="label">Stop notification when user is typing</span>
                <FieldSwitch setVal={setStopWhenTypingFlag} val={stopWhenTypingFlag}/>
            </p>
            <p className={"f-disabled"} title={"Coming soon"}>
                <span className="label">Allow users to close the notification</span>
                <FieldSwitch setVal={setAllowCloseFlag} val={allowCloseFlag}/>
            </p>
            <p className="link-btn">
                <span className="label">Behavior preview</span>
                <a href="/preview" onClick={doPreview} className="b"><i className="icon-play"></i> Preview</a>
            </p>
            {/*<h2>Time rules</h2>*/}
            {/*<p>*/}
            {/*    <span className="label">Time to show notification</span>*/}
            {/*    <FieldSwitch setVal={setTimeShowFlag} val={timeShowFlag}/>*/}
            {/*    <select id="fwm" name="fwm" disabled={!timeShowFlag}>*/}

            {/*        <option value="./">Timezone: CEST</option>*/}
            {/*        <option value="./">Position #1</option>*/}
            {/*        <option value="./">Position #2</option>*/}
            {/*        <option value="./">Position #3</option>*/}
            {/*        <option value="./">Position #4</option>*/}
            {/*        <option value="./">Position #5</option>*/}
            {/*    </select>*/}
            {/*    <span className="input-time">*/}
            {/*        <input type="text" id="fwn" name="fwn" placeholder="00:00" disabled={!timeShowFlag}/>*/}
            {/*        <span>-</span>*/}
            {/*        <input type="text" id="fwo" name="fwo" placeholder="00:00" disabled={!timeShowFlag}/>*/}
            {/*    </span>*/}
            {/*</p>*/}
            <StepSubmit activeStep={props.activeStep} setActiveStep={props.setActiveStep}
                        activeElement={props.activeElement}/>
        </StepFormWrapper>
    </Fragment>
}
