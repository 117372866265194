import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Field from "../../form/Field";
import {toast} from "react-toastify";
import {storeSetAuth} from "../../redux/actions";
import {useDispatch} from "react-redux";

const Parse = require('parse');

export default function SignupPage() {
    const history = useHistory();

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const goToLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        history.push('/login');
    }

    const addSignupClass = () => {
        document.getElementsByTagName('html')[0].classList.add('theme-signup');
    }
    const removeSignupClass = () => {
        document.getElementsByTagName('html')[0].classList.remove('theme-signup');
    }
    useEffect(() => {
        addSignupClass()
    }, []);

    const signUp = () => {
        if (!email.length || !password.length || !passwordRepeat.length) {
            toast.error('Please fill all required fields');
            return;
        }
        if (password !== passwordRepeat) {
            toast.error('Passwords don\'t match');
            return;
        }

        const user = new Parse.User();
        user.set("username", email);
        user.set("password", password);
        user.set("email", email);

        user.signUp().then(() => {
            dispatch(storeSetAuth(true));
            removeSignupClass();
            history.push('/notifications');
        }).catch((e: any) => {
            toast.error(e.message);
        })
    }

    return (
        <main id="content">
            <form method="post" className="form-signup">
                <header>
                    <h1>Welcome!<br/> Nice to see you</h1>
                    <p>Sign up and improve your business</p>
                </header>
                <Field title={"Enter email address"} fieldKey={"email"} val={email}
                       onChange={(key: string, val: string) => setEmail(val)}
                       required={true}/>

                <Field title={"Enter password"} fieldKey={"password"} val={password}
                       onChange={(key: string, val: string) => setPassword(val)} type={"password"}
                       required={true}/>

                <Field title={"Repeat password"} fieldKey={"passwordRepeat"} val={passwordRepeat}
                       onChange={(key: string, val: string) => setPasswordRepeat(val)} type={"password"}
                       required={true}/>

                <p>
                    <button type="button" onClick={signUp}>Sign Up</button>
                </p>
                <footer>
                    <h2>or</h2>
                    <p className="link-btn"><a href="./" className="gl">Continue with Google</a></p>
                    <p>Already have an account? <a href="/login" onClick={goToLogin}>Log in</a></p>
                </footer>
            </form>
        </main>
    )
}