import React from "react";
import {BFNotificationsProductEntity} from "../../../../../db";

const Parse = require('parse')

interface Props {
    index: number,
    removeProduct: (index: number) => void,
    copyProduct: (index: number) => void,
    item: BFNotificationsProductEntity,
    onItemChange: (index: number, item: BFNotificationsProductEntity) => void,
}

export default function ProductLine(props: Props) {
    const {item, onItemChange} = props;

    const onDataPropChange = (val: any, key: string) => {
        const newEl = item.clone();
        newEl.id = item.id;
        newEl.set(key, val);

        onItemChange(props.index, newEl);
    }

    const onFileChange = (e: any) => {
        const file = e.target.files[0];
        const parseFile = new Parse.File('photo.jpg', file);

        const newEl = item.clone();
        newEl.id = item.id;
        newEl.set('file', parseFile);
        newEl.set('fileName', file.name);

        onItemChange(props.index, newEl);
    }

    return (
        <tr>
            <td>{props.index + 1}</td>
            <td>
                <input type="text"
                       placeholder="Enter product name" required
                       value={!!item.get('name') ? item.get('name') : ''}
                       onChange={(e) => onDataPropChange(e.target.value, 'name')}
                />
            </td>
            <td>
                <span className={"link-btn " + (!item.get('fileName') ? '' : 'has-file')}>
                    {!!item.get('fileName') ? <span className="nowrap" title={item.get('fileName')}>{item.get('fileName')}</span> :
                        <span>Thumbnail not loaded</span>}

                    <label htmlFor={"product-file-" + props.index} className="a">
                        <input type="file" id={"product-file-" + props.index} onChange={onFileChange} accept="image/x-png, image/gif, image/jpeg, image/jpg"/>
                        <i className="icon-upload"></i> {!item.get('fileName') ? 'Upload' : ''}
                    </label>
                </span>
            </td>
            <td>
                <select value={!!item.get('gender') ? item.get('gender') : ''}
                        onChange={(e) => onDataPropChange(e.target.value, 'gender')}>
                    <option value="m">Male</option>
                    <option value="f">Female</option>
                    <option value="">Both</option>
                </select>
            </td>
            <td>
                <input type="url"
                       placeholder="Link"
                       value={!!item.get('url') ? item.get('url') : ''}
                       onChange={(e) => onDataPropChange(e.target.value, 'url')}
                />
            </td>
            <td>
                <ul className="list-icon">
                    <li><a href="/copy" onClick={e => {
                        e.preventDefault();
                        props.copyProduct(props.index)
                    }}><i className="icon-copy"></i> <span className="hidden">Copy</span></a></li>
                    <li><a href="/remove" onClick={e => {
                        e.preventDefault();
                        props.removeProduct(props.index)
                    }}><i className="icon-trash"></i> <span className="hidden">Remove</span></a>
                    </li>
                </ul>
            </td>
        </tr>
    )
}
