import React from "react";

interface Props {
    children: any;
    className?: string;
}

export default function StepFormWrapper({children, className}: Props) {
    const onSubmit = (e: React.FormEvent) => {
        // e.preventDefault();
    }
    return (
        <form onSubmit={onSubmit} method="post" className={"module-box" + (className ? " " + className : '')}>
            {children}
        </form>
    )
}