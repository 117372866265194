import {
    IReloadReducer,
    ISetReloadListAction,
    ISetReloadUserAction,
    SET_RELOAD_LIST,
    SET_RELOAD_USER
} from "../../types/redux.types";

const InitialState: IReloadReducer = {
    user: 0,
    list: 0,
};

export default function Reload(state = InitialState, action: ISetReloadListAction | ISetReloadUserAction) {
    switch (action.type) {
        case SET_RELOAD_LIST:
            return {...state, list: new Date().getTime()};
        case SET_RELOAD_USER:
            return {...state, user: new Date().getTime()};
        default:
            return state
    }
}
