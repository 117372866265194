import React, {Fragment, useEffect} from "react";
import {Redirect, Route} from "react-router-dom";
import SignupPage from "./pages/auth/SignupPage";
import LoginPage from "./pages/auth/LoginPage";
import NotificationsListPage from "./pages/notifications/list/NotificationsListPage";
import StepsPage from "./pages/notifications/new/StepsPage";
import AccountSettingsPage from "./pages/account-settings/AccountSettingsPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import {useSelector} from "react-redux";
import {IStore} from "./types/redux.types";
import PreviewPage from "./pages/notifications/PreviewPage";

const Parse = require('parse');

export default function AppRouter() {
    const isAuth = useSelector((state: IStore) => state.Auth.isAuth);
    let currentUser = Parse.User.current();

    useEffect(() => {
        currentUser = Parse.User.current();
    }, [isAuth]);

    return (
        <Fragment>
            <Route path={'/signup'} component={SignupPage}/>
            <Route path={'/login'} component={LoginPage}/>
            <Route path={'/password-reset'} component={ResetPasswordPage}/>

            {currentUser !== null ?
                <Fragment>
                    <Route path={'/'} exact component={NotificationsListPage}/>
                    <Route path={'/notifications'} exact component={NotificationsListPage}/>
                    <Route path={'/notifications/:id'} component={StepsPage}/>
                    <Route path={'/account-settings'} component={AccountSettingsPage}/>
                    <Route path={'/preview/:id'} component={PreviewPage}/>
                </Fragment>
                :
                <Fragment>
                    <Redirect to={`${process.env.PUBLIC_URL}/login`}/>
                </Fragment>
            }
        </Fragment>
    )
}
