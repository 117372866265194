import React from 'react';
import './assets/styles/screen.css';
import './assets/styles/custom.css';

import {BrowserRouter} from 'react-router-dom'
import AppRouter from "./AppRouter";
import AppStore from "./AppStore";
import {Slide, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BackendConfig} from "./config/BackendConfig";

const Parse = require('parse');

Parse.initialize(process.env.REACT_APP_PARSE_APPID, undefined, process.env.REACT_APP_PARSE_SECRET);
Parse.serverURL = BackendConfig.parseUrl;

function App() {
    return (
        <AppStore>
            <BrowserRouter>
                <AppRouter/>
            </BrowserRouter>
            <ToastContainer hideProgressBar transition={Slide} autoClose={2000}
            />
        </AppStore>
    );
}

export default App;
