import React from "react";
import {BFNotificationsEntity} from "../../../../db";

interface Props {
    item: BFNotificationsEntity
}

export default function BtnPreview(props: Props) {
    const {item} = props;
    const doPreview = (e: React.MouseEvent) => {
        e.preventDefault();

        try {
            // @ts-ignore
            window.bfClass.stop();
        } catch (e) {
        }

        item.save().then(() => {
            const bfScript = document.createElement("script");
            bfScript.src = item.getScriptUrl();
            bfScript.type = "text/javascript";
            document.body.appendChild(bfScript);
        });
    }
    return (
        <span className="link-btn">
            <a href="/preview" onClick={doPreview} className="c"><i
                className="icon-play"></i> Preview</a>
        </span>
    )
}
