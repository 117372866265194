import React from "react";
import {useHistory} from 'react-router-dom'

interface Props {
    textRight?: boolean
}

export default function NewButton(props: Props) {
    const history = useHistory();
    const addNew = (e?: React.MouseEvent) => {
        if (e) {
            e.preventDefault();
        }
        history.push('/notifications/new');
    }

    return (
        <p className={"link-btn" + (props.textRight ? ' text-right' : '')}>
            <a href="/notifications/new" onClick={addNew}><i className="icon-plus"></i> Add notification</a>
        </p>
    )
}