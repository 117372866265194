import {
    ISetAuthAction,
    ISetReloadListAction,
    ISetReloadUserAction,
    SET_AUTH,
    SET_RELOAD_LIST,
    SET_RELOAD_USER
} from "../types/redux.types";

export function storeSetAuth(payload: boolean): ISetAuthAction {
    return {
        type: SET_AUTH,
        payload
    }
}

export function storeReloadList(): ISetReloadListAction {
    return {
        type: SET_RELOAD_LIST,
    }
}

export function storeReloadUser(): ISetReloadUserAction {
    return {
        type: SET_RELOAD_USER,
    }
}
