import React, {Fragment} from "react";
import StepSubmit from "./StepSubmit";
import {BFNotificationsEntity, BFNotificationsProductEntity} from "../../../../db";
import Tooltip from "./Tooltip/Tooltip";
import ProductsList from "./Step2/ProductsList";
import FieldSwitch from "../../../../form/FieldSwitch";
import StepFormWrapper from "./StepFormWrapper";
import {toast} from "react-toastify";

const Parse = require('parse')

interface Props {
    activeStep: number,
    setActiveStep: (val: number) => void,

    activeElement: BFNotificationsEntity,
    onDataPropChange: (val: any, key: string) => void,
}

const texts = {
    actionText: {
        title: "Action text desc headline",
        text: "Simple match is the default URL match type, and it's ideal for activating Pages on a single URL. This match type ignores query and hash parameters."
    },
    messageIcon: {
        title: "Action text desc headline",
        text: "Simple match is the default URL match type, and it's ideal for activating Pages on a single URL. This match type ignores query and hash parameters."
    },
    locationFlag: {
        title: "Action text desc headline",
        text: "Simple match is the default URL match type, and it's ideal for activating Pages on a single URL. This match type ignores query and hash parameters."
    }
}

export default function Step2(props: Props) {
    const locationFlag = !!props.activeElement.get('locationFlag');
    const setLocationFlag = (val: boolean) => {
        props.onDataPropChange(val, 'locationFlag');
    }

    const onFileChange = (e: any) => {
        const file = e.target.files[0];
        const parseFile = new Parse.File('photo.jpg', file);

        props.onDataPropChange(file.name, 'messageIconName');
    }

    const isShowProducts = [0, 3, 4].indexOf(props.activeElement.getType()) > -1;
    const isShowMessageIcon = [0, 4].indexOf(props.activeElement.getType()) === -1;
    // const isShowLocation = props.activeElement.getType() === 0;
    const isShowLocation = false;

    const validate = () => {
        return new Promise((resolve) => {
            if (!props.activeElement.get('actionText')) {
                toast.error("Please add action text");
                return;
            }
            if (isShowProducts) {
                const products = props.activeElement.get('products');
                if (!products || products.length === 0) {
                    toast.error("Please add products");
                    return;
                }
                if (products.filter((item: BFNotificationsProductEntity) => !item.get('name')).length > 0) {
                    toast.error("Please fill product name");
                    return;
                }
            }
            resolve();
        });
    }

    return <Fragment>
        <StepFormWrapper>
            <h2>Message</h2>
            <div className="form-wide" style={{zIndex: 7}}>
                <p style={{zIndex: 6}}>
                    <label>
                        <Tooltip title={texts.actionText.title} text={texts.actionText.text}>Action text</Tooltip>
                    </label>

                    <input type="text"
                           placeholder="Enter message text (i.e. Bought, Subcribed, Visited, etc)"
                           value={props.activeElement.get('actionText')}
                           onChange={(e) => props.onDataPropChange(e.target.value, 'actionText')}
                    />
                </p>
                {isShowMessageIcon &&
                <p style={{zIndex: 5}} className={"file-upload"}>
                    <label htmlFor="fwb">
                        <Tooltip title={texts.messageIcon.title} text={texts.messageIcon.text}>
                            Message icon
                        </Tooltip>
                    </label>
                    <span className={"link-btn " + (!props.activeElement.get('messageIconName') ? '' : 'has-file')}>
                        {!!props.activeElement.get('messageIconName') ?
                            <span className="nowrap"
                                  title={props.activeElement.get('messageIconName')}>{props.activeElement.get('messageIconName')}</span> :
                            <span>Icon not loaded</span>}

                        <label htmlFor={"message-icon"} className={"a "}>
                            <input type="file" id={"message-icon"} onChange={onFileChange}
                                   accept="image/x-png, image/gif, image/jpeg, image/jpg"/>
                            <i className="icon-upload"></i> {!props.activeElement.get('messageIconName') ? 'Upload' : ''}
                        </label>
                    </span>
                </p>
                }
                {isShowLocation &&
                <p style={{zIndex: 4}}>
                    <span className="label">
                        <Tooltip title={texts.locationFlag.title} text={texts.locationFlag.text}>
                            Show location flag instead of text
                        </Tooltip>
                    </span>
                    <FieldSwitch setVal={setLocationFlag} val={locationFlag}/>
                </p>
                }
            </div>
            {isShowProducts &&
            <ProductsList activeElement={props.activeElement} onDataPropChange={props.onDataPropChange}/>
            }
            <StepSubmit activeStep={props.activeStep} setActiveStep={props.setActiveStep}
                        activeElement={props.activeElement}
                        validate={validate}/>
        </StepFormWrapper>
    </Fragment>
}
