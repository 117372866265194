import React, {useEffect, useState} from "react";

import {useHistory} from 'react-router-dom'
import Field from "../../form/Field";

const Parse = require('parse');

export default function ResetPasswordPage() {
    const [email, setEmail] = useState('');

    const history = useHistory();

    const goToLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        history.push('/login');
    }

    const addSignupClass = () => {
        document.getElementsByTagName('html')[0].classList.add('theme-signup');
    }
    useEffect(() => {
        addSignupClass()
    }, []);

    const doPasswordReset = () => {
        Parse.User.requestPasswordReset(email).then((res: any) => {
            history.push('/login');
        }).catch((e: any) => {
            console.log('login error', e);
        })
    }

    return (
        <main id="content">
            <form method="post" className="form-signup">
                <header>
                    <h1>Password reset!</h1>
                    {/*<p>Sign up and improve your business</p>*/}
                </header>
                <Field title={"Enter email address"} fieldKey={"email"} val={email}
                       onChange={(key: string, val: string) => setEmail(val)}
                       required={true}/>


                <p>
                    <button type="button" onClick={doPasswordReset}>Reset</button>
                </p>
                <footer>
                    <h2>or</h2>
                    <p>Already have an account? <a href="/login" onClick={goToLogin}>Log in</a></p>
                </footer>
            </form>
        </main>
    )
}
