import {BFNotificationsEntity} from "./BFNotificationsEntity";
import {BFNotificationsProductEntity} from "./BFNotificationsProductEntity"
import {BFNotificationsMatchUrlEntity} from "./BFNotificationsMatchUrlEntity"
import {BFNotificationsNotMatchUrlEntity} from "./BFNotificationsNotMatchUrlEntity"

const Parse = require('parse');

Parse.Object.registerSubclass('BFNotificationsEntity', BFNotificationsEntity);
Parse.Object.registerSubclass('BFNotificationsProductEntity', BFNotificationsProductEntity);
Parse.Object.registerSubclass('BFNotificationsMatchUrlEntity', BFNotificationsMatchUrlEntity);
Parse.Object.registerSubclass('BFNotificationsNotMatchUrlEntity', BFNotificationsNotMatchUrlEntity);

export {
    BFNotificationsEntity,
    BFNotificationsProductEntity,
    BFNotificationsMatchUrlEntity,
    BFNotificationsNotMatchUrlEntity,
}
