import React from "react";
import {BFNotificationsEntity} from "../../../../db";
import {useDispatch} from "react-redux";
import {storeReloadList} from "../../../../redux/actions";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

interface Props {
    item: BFNotificationsEntity
}

export default function BtnRemove(props: Props) {
    const dispatch = useDispatch();
    const link = '/notifications/' + props.item.id + '/remove';
    const remove = (e: React.MouseEvent) => {
        e.preventDefault();

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete notification "' + props.item.getTitle() + '"?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => props.item.destroy().then(() => {
                        dispatch(storeReloadList())
                    })
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });

    }

    return (
        <a href={link} onClick={remove}>
            <i className="icon-trash"></i> <span className="hidden">Remove</span>
        </a>
    )
}
