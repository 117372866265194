import React, {Fragment} from "react";
import StepSubmit from "./StepSubmit";
import {BFNotificationsEntity} from "../../../../db";
import StepFormWrapper from "./StepFormWrapper";
import {toast} from "react-toastify";
import CopyBlock from "./Step5/CopyBlock";

interface Props {
    activeStep: number,
    setActiveStep: (val: number) => void,

    activeElement: BFNotificationsEntity,
    onDataPropChange: (val: any, key: string) => void,
}

export default function Step5(props: Props) {

    const text = `<script type="text/javascript" src="${props.activeElement.getScriptUrl()}"></script>`;

    const validate = () => {
        return new Promise((resolve) => {
            if (!props.activeElement.get('pageType')) {
                toast.error("Please choose type");
            }
            resolve();
        });
    }


    const setPageType = (val: string) => {
        props.onDataPropChange(val, 'pageType');
    }

    const noop = () => {
    };

    return <Fragment>
        <StepFormWrapper>
            <h2>Activate</h2>
            <CopyBlock activeElement={props.activeElement}/>
            <ul className="input-box">
                <li onClick={() => setPageType('shopify')}>
                    <input type="checkbox" onChange={noop} checked={props.activeElement.get('pageType') === 'shopify'}/>
                    <label><i className="icon-shopify"></i> Shopify</label>
                </li>
                <li onClick={() => setPageType('wordpress')}>
                    <input type="checkbox" onChange={noop}
                           checked={props.activeElement.get('pageType') === 'wordpress'}/>
                    <label><i className="icon-wordpress"></i> Wordpress</label>
                </li>
                <li onClick={() => setPageType('wix')}>
                    <input type="checkbox" onChange={noop} checked={props.activeElement.get('pageType') === 'wix'}/>
                    <label><i className="icon-wix"></i> Wix</label>
                </li>
                <li onClick={() => setPageType('custom')}>
                    <input type="checkbox" onChange={noop} checked={props.activeElement.get('pageType') === 'custom'}/>
                    <label><i className="icon-check"></i> Custom</label>
                </li>
                {/*<li>*/}
                {/*    <input type="checkbox" id={"fwf"} name={"fwf"}/>*/}
                {/*    <label htmlFor="fwf"><i className="icon-check"></i> Custom</label>*/}
                {/*</li>*/}
            </ul>
            {!!props.activeElement.get('pageType') &&
            <Fragment>
                <h3 className="s14">How to install Blackfomo notifications on Shopify</h3>
                <p>Below you’ll find the detailed step-by-step instruction on how to connect Blackfomo and Shopify.</p>
                <ol>
                    <li>Names are generated from real people based on your visitor’s location.</li>
                    <li>There are plenty of sales notification tools on the market, but they lack features which
                        actually
                        increase sales.
                    </li>
                    <li>After hundreds of split and multivariate tests, we discovered two key factors that influence
                        people
                        to buy
                    </li>
                    <li>8,000+ Growth Hackers use Blackfomo to boost social proof and increase their sales conversion
                        rate
                    </li>
                    <li>Youve succesfully installed Blackfomo notifications on Shopify! Great job!</li>
                </ol>
            </Fragment>
            }
            <StepSubmit activeStep={props.activeStep} setActiveStep={props.setActiveStep} nextTitle={"Activate"}
                        activeElement={props.activeElement}
                        validate={validate}/>
        </StepFormWrapper>
    </Fragment>
}
