import React, {Fragment, useEffect, useRef, useState} from "react";
import ReactTooltip from "react-tooltip";

interface Props {
    title: string,
    fieldKey: string,
    val: string,
    onChange: (key: string, val: string) => void,
    required?: boolean,
    onRef?: any,
    tipText?: string,
    errorText?: string,
    type?: string,
    readonly?: boolean,
    readonlyChangeTitle?: string,
    onBlur?: () => void,
    children?: any,
    placeholder?: string,
    onSave?: () => void,
}

export default function Field({
                                  readonly,
                                  readonlyChangeTitle,
                                  title,
                                  fieldKey,
                                  required,
                                  val,
                                  onChange,
                                  onRef,
                                  tipText,
                                  errorText,
                                  type = "text",
                                  onBlur,
                                  children,
                                  placeholder,
                                  onSave
                              }: Props) {
    const [isFocus, setFocus] = useState(false);
    const [isError, setError] = useState(false);
    const [isReadonly, setIsReadonly] = useState(readonly);
    const fieldRef = useRef();

    let pClassName = [];
    if (val.length > 0 || isFocus) {
        pClassName.push("focus");
    }
    if (isError) {
        pClassName.push("has-error");
    }
    let iClassName = [];
    if (isError) {
        iClassName.push("is-error");
    }
    const onFieldBlur = () => {
        setFocus(false);
        checkError();
        if (onBlur) {
            onBlur();
        }
    }

    const validateEmail = (email: string) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

    }

    const checkError = () => {
        if (type === 'email') {
            if (!validateEmail(val)) {
                setError(true);
                return;
            }
        }
        if (required && val.length === 0) {
            setError(true);
        } else {
            setError(false);
        }
    }

    const checkErrorOnFocus = () => {
        if (isFocus) {
            checkError();
        }
    }

    const checkErrorListeners = () => {
        document.addEventListener("checkError", checkError);
        return () => {
            document.removeEventListener("checkError", checkError);
        }
    }

    useEffect(checkErrorOnFocus, [val]);
    useEffect(checkErrorListeners, [val])

    const htmlKey = "f-" + fieldKey;

    const changeReadonly = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsReadonly(false);
        if (fieldRef && fieldRef.current) {
            //@ts-ignore
            fieldRef.current.focus();
        }
    }

    const setReadonly = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsReadonly(true);
    }


    return (
        <p className={pClassName.join(" ")}>
            {!!tipText && <Fragment><span className="tip" data-tip={tipText}></span><ReactTooltip/>
            </Fragment>}
            <label htmlFor={htmlKey}>{title}</label>
            <input type={type} id={htmlKey}
                   name={htmlKey}
                   required={required}
                   value={val}
                   onChange={(e) => onChange(fieldKey, e.target.value)}
                   onFocus={() => setFocus(true)}
                   onBlur={onFieldBlur}
                   ref={onRef ? onRef : fieldRef}
                   placeholder={title}
                   readOnly={isReadonly}
                   className={iClassName.join(' ')}
            />
            {isReadonly && !!readonlyChangeTitle &&
            <a href="/change" onClick={changeReadonly}>{readonlyChangeTitle}</a>}
            {!isReadonly && !!readonlyChangeTitle &&
            <a href="/change" className={"active"} onClick={(e) => {
                setReadonly(e);
                if (onSave) {
                    onSave();
                }
            }}>{readonlyChangeTitle}</a>}
            {isError &&
            <label className="error"
                   style={{display: 'block'}}
                   htmlFor={htmlKey}>{errorText ? errorText : "This field cannot be left blank"}</label>}
            {!!children && children}
        </p>
    )
}
