import React, {Fragment, useState} from "react";
import StepSubmit from "./StepSubmit";
import {BFNotificationsEntity, BFNotificationsMatchUrlEntity} from "../../../../db";
import StepFormWrapper from "./StepFormWrapper";
import MatchTable from "./Step3/MatchTable";
import {toast} from "react-toastify";

interface Props {
    activeStep: number,
    setActiveStep: (val: number) => void,

    activeElement: BFNotificationsEntity,
    onDataPropChange: (val: any, key: string) => void,
}

const texts = {
    match: {
        all: 'This notification will be shown on every page of your website.',
        contains: 'Display on all page URLs that contain the following expression.',
        simple: 'Add one or more page URLs or copy them directly from your web browser for best results.',
    },
    notMatch: {
        text: 'We will not show notifications on the pages that fit the criteria below.\n' +
            'e.g. URL contains = products will not show in pages that include products in the URL.'
    }
}

export default function Step3(props: Props) {
    const [notMatchType, setNotMatchType] = useState('simple');
    const [matchUrl, setMatchUrl] = useState('');
    const [notMatchUrl, setNotMatchUrl] = useState('');

    const matchType = props.activeElement.getPageMatchType();
    const setMatchType = (val: string) => {
        props.onDataPropChange(val, 'matchType');
    }

    const matches = !!props.activeElement.get('matchUrls') ? props.activeElement.get('matchUrls') : [];
    const notMatches = !!props.activeElement.get('notMatchUrls') ? props.activeElement.get('notMatchUrls') : [];

    const addMatchUrl = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!matchUrl) {
            toast.error('Please add url');
            return;
        }

        const newEl = new BFNotificationsMatchUrlEntity();
        newEl.set('type', matchType);
        newEl.set('url', matchUrl);

        props.onDataPropChange([...matches, newEl], 'matchUrls');
        setMatchUrl('');
    }

    const addNotMatchUrl = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!notMatchUrl) {
            toast.error('Please add url');
            return;
        }

        const newEl = new BFNotificationsMatchUrlEntity();
        newEl.set('type', notMatchType);
        newEl.set('url', notMatchUrl);

        props.onDataPropChange([...notMatches, newEl], 'notMatchUrls');
        setNotMatchUrl('');
    }

    const removeMatchUrl = (index: number) => {
        props.onDataPropChange([...matches.filter((v: any, i: number) => i !== index)], 'matchUrls');
    }

    const removeNotMatchUrl = (index: number) => {
        props.onDataPropChange([...notMatches.filter((v: any, i: number) => i !== index)], 'notMatchUrls');
    }

    // @ts-ignore
    const matchText = texts.match[matchType];

    return <Fragment>
        <StepFormWrapper className="form-wide">
            <h2>Display</h2>
            <p>
                <span className="label">Select URL match type</span>
                <span className="input-check">
                    <span>
                        <input type="radio" id="fwa" name="fwa" checked={matchType === 'simple'}
                               onChange={() => setMatchType('simple')}/>
                        <label htmlFor="fwa">Simple</label>
                    </span>
                    <span>
                        <input type="radio" id="fwb" name="fwa" checked={matchType === 'contains'}
                               onChange={() => setMatchType('contains')}/>
                        <label htmlFor="fwb">Contains</label>
                    </span>
                    <span>
                        <input type="radio" id="fwc" name="fwa" checked={matchType === 'all'}
                               onChange={() => setMatchType('all')}/>
                        <label htmlFor="fwc">All pages</label>
                    </span>
                    <span className="wide">{matchText}</span>
                </span>
            </p>
            {!props.activeElement.isAllPageMatchType() &&
            <Fragment>
                <p>
                    <label className="hidden">Page URL / Expression / or no need to show this part</label>
                    <input type="text"
                           placeholder="Page URL / Expression / or no need to show this part"
                           value={matchUrl}
                           onChange={(e) => setMatchUrl(e.target.value)}
                    />
                    <a href="/add" onClick={addMatchUrl} className="btn">Add</a>
                </p>

                <MatchTable matches={matches} removeMatchUrl={removeMatchUrl}/>
            </Fragment>
            }

            {props.activeElement.isAllPageMatchType() &&
            <Fragment>
                <h2 className="m10">Where <span className="text-uppercase">not</span> to display</h2>
                <p className="m25">{texts.notMatch.text}</p>
                <p>
                    <label htmlFor="fwe">URL</label>
                    <select value={notMatchType} onChange={e => setNotMatchType(e.target.value)} className="w135">
                        <option value="simple">Simple</option>
                        <option value="contains">Contains</option>
                    </select>
                    <input type="url" value={notMatchUrl} onChange={e => setNotMatchUrl(e.target.value)}
                           placeholder="Page URL"/>
                    <a href="/add" onClick={addNotMatchUrl} className="btn">Add</a>
                </p>
                <MatchTable matches={notMatches} removeMatchUrl={removeNotMatchUrl}/>
            </Fragment>
            }
            <StepSubmit activeStep={props.activeStep} setActiveStep={props.setActiveStep}
                        activeElement={props.activeElement}/>
        </StepFormWrapper>
    </Fragment>
}
