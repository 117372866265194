import React from "react";
import AppLayout from "../app/AppLayout";
import AccountSettings from "./AccountSettings";

export default function AccountSettingsPage() {
    return (
        <AppLayout>
            <AccountSettings/>
        </AppLayout>
    )
}