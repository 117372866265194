import React, {Fragment} from "react";
import AppHeader from "./header/AppHeader";

interface Props {
    children: React.ReactChild,
}

export default function AppLayout({children}: Props) {
    return (
        <Fragment>
            <AppHeader/>
            {children}
        </Fragment>
    )
}