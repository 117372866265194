import {combineReducers} from 'redux';

import Auth from "./auth.reducer";
import Reload from "./reload.reducer";

const reducers = combineReducers({
    Auth,
    Reload,
});

export default reducers;
