import React from "react";
import {useHistory, useRouteMatch} from 'react-router-dom';

const Parse = require('parse');

export default function AppMenu() {
    const history = useHistory();
    const {url} = useRouteMatch();

    const trimByChar = (string: string, charToRemove: string) => {
        while (string.charAt(0) === charToRemove) {
            string = string.substring(1);
        }

        while (string.charAt(string.length - 1) === charToRemove) {
            string = string.substring(0, string.length - 1);
        }

        return string
    }

    const goToNotifications = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/notifications');
    }
    const goToAccountSettings = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/account-settings');
    }

    const activeUrl = trimByChar(url, '/');

    const isNotifications = activeUrl === '' || activeUrl.indexOf('notifications') === 0;

    const doLogout = (e: any) => {
        e.preventDefault();
        Parse.User.logOut();
        history.push('/login');
    }

    return (
        <nav id="nav" aria-label="Navigation">
            <ul>
                <li className={isNotifications ? "active" : ""}>
                    <a href="/notifications" onClick={goToNotifications}>
                        <i className="icon-bell"></i> Notifications
                    </a>
                </li>
                <li className={activeUrl === '/' || activeUrl === 'account-settings' ? "active" : ""}>
                    <a href="/account-settings" onClick={goToAccountSettings}>
                        <i className="icon-cog"></i> Account Settings</a>
                </li>
                <li>
                    <a href="./">
                        <i className="icon-credit-card"></i> Plans and Payments
                    </a>
                </li>
                <li>
                    <a href="./"><i className="icon-comment-help"></i> User Guide</a>
                </li>
                <li><a href="./"><i className="icon-transaction"></i> Referrals</a></li>
                <li><a href="/logout" onClick={doLogout}>Sign out</a></li>
            </ul>
        </nav>
    )
}
