import React from "react";

export default function VisitorsPreview() {
    return (
        <div>
            <p className="scheme-icon"><i className="icon-thumb-up"></i> Visitors - notification
                preview
            </p>
            <p className="module-inline strong"><span>99</span> Users Online</p>
        </div>
    )
}
