import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import ContentBg from "../../../assets/images/content/content-background.png"
import {BFNotificationsEntity} from "../../../db";
import NewButton from "./components/NewButton";
import NotificationRow from "./components/NotificationRow";
import {useSelector} from "react-redux";
import {IStore} from "../../../types/redux.types";
import CopyBlock from "../new/content/Step5/CopyBlock";

const Parse = require('parse');

export default function NotificationsList() {
    const reloadList = useSelector((state: IStore) => state.Reload.list);

    const [data, setData] = useState<BFNotificationsEntity[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [trackingCodeElement, setTrackingCodeElement] = useState();
    const history = useHistory();

    const addNew = (e?: React.MouseEvent) => {
        if (e) {
            e.preventDefault();
        }
        history.push('/notifications/new');
    }

    const getData = () => {
        const query = new Parse.Query(BFNotificationsEntity);
        query.find().then((res: BFNotificationsEntity[]) => {
            setData(res);
            setIsDataLoaded(true);
        })
    }

    useEffect(getData, [reloadList]);

    if (!isDataLoaded && !data.length) {
        return <Fragment/>
    }

    return (
        <Fragment>
            {
                data.length === 0 ?
                    <Fragment>
                        <main id="content" className="text-center">
                            <article className="module-notification">
                                <h1>Add your first notification</h1>
                                <NewButton/>
                                {/*<form method="post" style={{display: 'block'}}>
                                    <p>
                                        <label htmlFor="mna">Enter title for notification</label>
                                        <input type="text" id="mna" name="mna"
                                               placeholder="Enter title for notification"
                                               required/>
                                    </p>
                                    <button type="button" onClick={addNew}>
                                        <i className="icon-plus"></i> Add notification
                                    </button>
                                </form>*/}
                            </article>
                            <figure className="background">
                                <img src={ContentBg} alt="" width="444" height="332"/>
                            </figure>
                        </main>
                    </Fragment> :

                    <Fragment>
                        <main id="content">
                            <form action=".." method="post">
                                <NewButton/>
                                <div className={"table-wrapper"}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                            <th>Preview</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.map((item: BFNotificationsEntity) => {
                                            return (
                                                <NotificationRow item={item} key={'nf-' + item.id}
                                                                 setTrackingCodeElement={setTrackingCodeElement}/>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                            {trackingCodeElement &&
                            <div className={"modal-tracking-window"} onClick={() => setTrackingCodeElement(undefined)}>
                                <div className={"modal-tracking"}>
                                    <CopyBlock activeElement={trackingCodeElement}/>
                                </div>
                            </div>
                            }
                        </main>
                    </Fragment>
            }
        </Fragment>
    )
}
