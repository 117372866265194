import React from "react";
import {store} from "./redux/store";
import {Provider} from "react-redux";
import AppAuth from "./AppAuth";

interface Props {
    children: any,
}

export default function AppStore(props: Props) {
    return (
        <Provider store={store}>
            <AppAuth>
                {props.children}
            </AppAuth>
        </Provider>
    )
}
