import React from "react";

export default function NewUsersPreview() {
    return (
        <div>
            <p className="scheme-icon"><i className="icon-thumb-up"></i> New users - notification
                preview</p>
            <div className="module-inline">
                <h3>15 649 <span>subscriptions</span></h3>
                <p>in the last<br/> 30 days</p>
            </div>
        </div>
    )
}