import React, {useState} from "react";
import {BFNotificationsEntity} from "../../../../db";
import BtnEdit from "./BtnEdit";
import BtnRemove from "./BtnRemove";
import SwitchActivated from "./SwitchActivated";
import BtnPreview from "./BtnPreview";
import {useHistory} from "react-router-dom";

interface Props {
    item: BFNotificationsEntity,
    setTrackingCodeElement: (i: BFNotificationsEntity) => void,
}

interface Step1Type {
    title: string,
    icon: string,
}

const types: Step1Type[] = [
    {title: 'Sales', icon: 'icon-dollar'},
    {title: 'New users', icon: 'icon-user-plus'},
    {title: 'Visitors', icon: 'icon-users'},
    {title: 'Subscribers', icon: 'icon-layers'},
    {title: 'Reviews', icon: 'icon-thumb-up'}
]

export default function NotificationRow(props: Props) {
    const {item} = props;
    const activeType = !!item.get('type') ? item.get('type') : 0;

    const [showTracking, setShowTracking] = useState(false);

    const link = '/notifications/' + props.item.id;
    const history = useHistory();
    const goToLink = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(link);
    }

    return (
        <tr>
            <td onClick={goToLink} className="strong hover">{item.get('title')}</td>
            <td onClick={goToLink} className={"hover"}><span className="scheme-icon"><i
                className={types[activeType].icon}></i> {types[activeType].title}</span>
            </td>
            <td>
                <SwitchActivated item={item}/>
            </td>
            <td>
                <ul className="list-icon">
                    <li className="link-btn">
                        <a href="/show" className="c" onClick={(e) => {
                            e.preventDefault();
                            props.setTrackingCodeElement(item);
                        }}>Tracking Code</a>


                    </li>
                    <li><BtnEdit item={item}/></li>
                    <li><BtnRemove item={item}/></li>
                </ul>
            </td>
            <td>
                <BtnPreview item={item}/>
            </td>
        </tr>
    )
}
