import React, {useState} from "react";
import Field from "../../form/Field";
import {useDispatch} from "react-redux";
import {storeReloadUser} from "../../redux/actions";
import {toast} from "react-toastify";

const Parse = require('parse');

export default function AccountSettings() {
    const dispatch = useDispatch();
    const currentUser = Parse.User.current();

    const [name, setName] = useState(!!currentUser.get('fullName') ? currentUser.get('fullName') : '');
    const [email, setEmail] = useState(!!currentUser.get('email') ? currentUser.get('email') : '');
    const [password, setPassword] = useState('');
    const [avatarName, setAvatarName] = useState(!!currentUser.get('avatarName') ? currentUser.get('avatarName') : 'Avatar not loaded');

    const onNameChange = () => {
        currentUser.set('fullName', name);
        currentUser.save().then((res: any) => {
            console.log('user saved');
            dispatch(storeReloadUser());
            toast.success("Name changed");
        }).catch((e: any) => {
            console.log('user error', e);
        });
    }

    const onEmailChange = () => {
        currentUser.set('email', email);
        currentUser.set('username', email);
        currentUser.save().then((res: any) => {
            console.log('user saved');
            dispatch(storeReloadUser());
            toast.success("Email changed");
        }).catch((e: any) => {
            console.log('user error', e);
        });
    }

    const onPasswordChange = () => {
        currentUser.set('password', password);
        currentUser.save().then((res: any) => {
            toast.success("Password changed");
        }).catch((e: any) => {
            console.log('user error', e);
        });
    }

    const onFileChange = (e: any) => {
        const avatar = e.target.files[0];
        if (!avatar) {
            return;
        }
        const parseFile = new Parse.File('avatar.jpg', avatar);
        currentUser.set('avatar', parseFile);
        currentUser.set('avatarName', avatar.name);
        currentUser.save().then((res: any) => {
            console.log('user saved');
            setAvatarName(avatar.name);
            dispatch(storeReloadUser());
            toast.success("Avatar changed");
        }).catch((e: any) => {
            console.log('user error', e);
        });
    }

    return (
        <main id="content">
            <article className="module-box">
                <h1>Account Settings</h1>
                <form className="form-settings">
                    <Field readonly={true} title={"Name"} fieldKey={"name"} val={name}
                           onChange={(key: string, val: string) => setName(val)} readonlyChangeTitle={"Change name"}
                           onSave={onNameChange}/>
                    <Field readonly={true} title={"Email"} fieldKey={"email"} val={email}
                           onChange={(key: string, val: string) => setEmail(val)} readonlyChangeTitle={"Change email"}
                           onSave={onEmailChange}/>
                    <Field readonly={true} title={"Password"} fieldKey={"password"} val={password}
                           onChange={(key: string, val: string) => setPassword(val)}
                           readonlyChangeTitle={"Change password"}
                           onSave={onPasswordChange}/>

                    <p>
                        <span className="label">Profile image</span>
                        <span
                            className="input"></span>
                        <label htmlFor="avatar-change">
                            <input type="file"
                                   id={'avatar-change'}
                                   onChange={onFileChange}
                                   accept="image/x-png, image/gif, image/jpeg, image/jpg"/> Change
                            profile image
                        </label>
                    </p>
                </form>
            </article>
        </main>
    )
}
