import React from "react";
import {BFNotificationsEntity} from "../../../../db";

import {useHistory} from 'react-router-dom';

interface Props {
    item: BFNotificationsEntity
}

export default function BtnEdit(props: Props) {
    const link = '/notifications/' + props.item.id;
    const history = useHistory();
    const goToLink = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(link);
    }

    return (
        <a href={link} onClick={goToLink}>
            <i className="icon-edit"></i> <span className="hidden">Edit</span>
        </a>
    )
}
