import React from "react";
import {BFNotificationsEntity} from "../../../../db";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";

interface Props {
    activeStep: number,
    setActiveStep: (val: number) => void,
    nextTitle?: string,
    validate?: () => Promise<any>,
    activeElement: BFNotificationsEntity,
}

export default function StepSubmit(props: Props) {
    const {id} = useParams();
    const history = useHistory();
    const {activeStep, setActiveStep, nextTitle, activeElement, validate} = props;
    const goBack = () => {
        const step = Math.max(activeStep - 1, -1);
        setActiveStep(step);
    }

    const goNext = (e: any) => {
        e.preventDefault();
        const nextStep = Math.min(activeStep + 1, 5);

        const go = () => {
            if (nextStep === 5) {
                activeElement.set('activated', true);
                activeElement.save().then((el: BFNotificationsEntity) => {
                    toast.success("Activated");

                    history.replace('/notifications');
                });

            } else if (nextStep >= 1) {
                // if (activeElement.)
                toast.success("The data has been saved");
                activeElement.set('lastStep', Math.max(activeElement.getLastStep(), nextStep));
                activeElement.save().then((el: BFNotificationsEntity) => {
                    if (id === 'new') {
                        history.replace('/notifications/' + el.id);
                    }
                });
                setActiveStep(nextStep);
            }
        }

        if (validate) {
            validate().then(res => {
                go();
            });
        } else {
            go();
        }
    }

    return (
        <p className="submit">
            <button type="reset" onClick={goBack}>Back</button>
            <button type="submit" onClick={goNext}>{nextTitle ? nextTitle : "Next"}</button>
        </p>
    )
}
