import {v4 as uuidv4} from 'uuid';
import {BackendConfig} from "../config/BackendConfig";

const Parse = require('parse');

export class BFNotificationsEntity extends Parse.Object {
    constructor() {
        super('BFNotificationsEntity');
    }

    getScriptUrl = () => {
        return BackendConfig.url + '/config/js/' + this.get('url');
    }

    isValidStep1 = () => {
        return !!this.getTitle();
    }

    isValidStep2 = () => {
        return !!this.get('actionText');
    }

    setDefaults = () => {
        // common
        this.setACL(new Parse.ACL(Parse.User.current()));
        this.set('url', uuidv4());

        // step 1
        this.set('title', '');
        this.set('type', 0);

        // step 2
        this.set('locationFlag', true);
        this.set('actionText', '');

        // step 3
        this.set('matchType', 'all');

        // step 4
        this.set('timeShowFlag', false);
        this.set('allowCloseFlag', false);
        this.set('stopWhenTypingFlag', false);
        this.set('hideOnMobileFlag', false);
        this.set('position', 'bottom-left');

        this.set('rangeFrom', "1");
        this.set('rangeTo', "100");

        this.set('maxNotificationOnPage', "5");
        this.set('spaceEachNotification', "7");
        this.set('displayEachNotificationFor', "5");
        this.set('delayFirstNotificationFor', "3");
        this.set('mobilePosition', "top");

        // step 5
        this.set('pageType', '');
        this.set('activated', false);
        this.set('lastStep', 0);
    }

    getLastStep = () => {
        return typeof this.get('lastStep') !== 'undefined' ? this.get('lastStep') : 0;
    }

    // step1
    getTitle = () => {
        return typeof this.get('title') !== 'undefined' ? this.get('title') : '';
    }

    getType = () => {
        return typeof this.get('type') !== 'undefined' ? this.get('type') : 0;
    }

    isActivated = () => {
        return typeof this.get('activated') !== 'undefined' ? this.get('activated') : false;
    }

    isAllPageMatchType = () => {
        const matchType = this.getPageMatchType();
        return matchType === 'all';
    }

    getPageMatchType = () => {
        return typeof this.get('matchType') !== 'undefined' ? this.get('matchType') : 'simple';
    }

    getMaxNotificationOnPage = () => {
        return typeof this.get('maxNotificationOnPage') !== 'undefined' ? this.get('maxNotificationOnPage') : "5";
    }
    getSpaceEachNotification = () => {
        return typeof this.get('spaceEachNotification') !== 'undefined' ? this.get('spaceEachNotification') : "7";
    }
    getDisplayEachNotificationFor = () => {
        return typeof this.get('displayEachNotificationFor') !== 'undefined' ? this.get('displayEachNotificationFor') : "5";
    }
    getDelayFirstNotificationFor = () => {
        return typeof this.get('delayFirstNotificationFor') !== 'undefined' ? this.get('delayFirstNotificationFor') : "3";
    }

    getMobilePosition = () => {
        return this.get('mobilePosition');
    }
}
