import React, {Fragment} from "react";
import ProductLine from "./ProductLine";
import {BFNotificationsEntity, BFNotificationsProductEntity} from "../../../../../db";

interface Props {
    activeElement: BFNotificationsEntity,
    onDataPropChange: (val: any, key: string) => void,
}

export default function ProductsList(props: Props) {

    const getEmpty = () => {
        const el = new BFNotificationsProductEntity();
        el.setDefaults();
        return el;
    }
    
    const products: BFNotificationsProductEntity[] = !!props.activeElement.get('products') && props.activeElement.get('products').length > 0 ? props.activeElement.get('products') : [getEmpty()];

    const addProduct = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onDataPropChange([...products, getEmpty()], 'products');
    }

    const removeProduct = (index: number) => {
        props.onDataPropChange([...products.filter((v, i: number) => i !== index)], 'products');
    }

    const copyProduct = (index: number) => {
        props.onDataPropChange([...products, products[index].clone()], 'products');
    }

    const onProductKeyChange = (index: number, item: BFNotificationsProductEntity) => {
        props.onDataPropChange([...products.map((v, i: number) => i === index ? item : v)], 'products');
    }

    return (
        <Fragment>
            <h2>Products
                <span className="link-btn">
                    <a href="/add" onClick={addProduct} className="inv"><i className="icon-plus"></i> Add product</a>
                </span>
            </h2>
            <table className="table-list">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Product name</th>
                    <th>Thumbnail</th>
                    <th>Gender</th>
                    <th colSpan={2}>Link</th>
                </tr>
                </thead>
                <tbody>
                {products.map((item: BFNotificationsProductEntity, index: number) => {
                    return (<ProductLine index={index}
                                         removeProduct={removeProduct}
                                         copyProduct={copyProduct}
                                         item={item}
                                         onItemChange={onProductKeyChange}
                                         key={"pr-" + index + '-' + item.id}
                    />)
                })}
                </tbody>
            </table>
        </Fragment>
    )
}