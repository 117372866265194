import React, {useEffect, useState} from "react";
import AppLayout from "../app/AppLayout";
import {useParams} from "react-router-dom";
import {BFNotificationsEntity} from "../../db";
import ContentBg from "../../assets/images/content/content-background.png";

const Parse = require('parse');

export default function PreviewPage() {
    const {id} = useParams();
    const newEl = new BFNotificationsEntity();
    newEl.setDefaults();

    const [activeElement, setActiveElement] = useState<BFNotificationsEntity>(newEl);

    const doStop = (e: React.MouseEvent) => {
        e.preventDefault();
        try {
            // @ts-ignore
            window.bfClass.stop();
        } catch (e) {
        }
    }

    const doPreview = (e: React.MouseEvent) => {
        doStop(e);

        // props.activeElement.save().then(() => {
        const bfScript = document.createElement("script");
        bfScript.src = activeElement.getScriptUrl();
        bfScript.type = "text/javascript";
        document.body.appendChild(bfScript);
        // });
    }


    const getData = () => {
        const query = new Parse.Query(BFNotificationsEntity);
        query.include('products');
        query.include('matchUrls');
        query.include('notMatchUrls');
        query.get(id)
            .then((item: BFNotificationsEntity) => {
                setActiveElement(item);
            }, (error: any) => {
            });
    }
    useEffect(getData, [id]);
    return (
        <AppLayout>
            {!!activeElement &&
            (<main id="content" className="text-center">
                    <article className="module-notification">
                        <h1>Notification {activeElement.getTitle()} preview</h1>
                        <button type="button" onClick={doPreview}>
                            start
                        </button>

                        <button type="reset" onClick={doStop}>
                            stop
                        </button>
                    </article>
                    <figure className="background">
                        <img src={ContentBg} alt="" width="444" height="332"/>
                    </figure>
                </main>
            )
            }
        </AppLayout>
    )
}
