import React from "react";
import {BFNotificationsEntity} from "../../../../db";
import FieldSwitch from "../../../../form/FieldSwitch";
import {useDispatch} from "react-redux";
import {storeReloadList} from "../../../../redux/actions";

interface Props {
    item: BFNotificationsEntity
}

export default function SwitchActivated(props: Props) {
    const dispatch = useDispatch();

    const {item} = props;
    const isActivated = item.isActivated();

    const saveActivated = (val: boolean) => {
        item.set('activated', val);
        item.save().then(() => {
            dispatch(storeReloadList());
        });
    }

    return (
        <FieldSwitch val={isActivated} setVal={saveActivated}/>
    )
}