import React from "react";
import StepSubmit from "./StepSubmit";
import SalesPreview from "./Step1Previews/SalesPreview";
import NewUsersPreview from "./Step1Previews/NewUsersPreview";
import VisitorsPreview from "./Step1Previews/VisitorsPreview";
import SubscribersPreview from "./Step1Previews/SubscribersPreview";
import {BFNotificationsEntity} from "../../../../db";
import StepFormWrapper from "./StepFormWrapper";
import Field from "../../../../form/Field";
import {toast} from "react-toastify";

const Parse = require('parse');

interface Props {
    activeStep: number,
    setActiveStep: (val: number) => void,

    activeElement: BFNotificationsEntity,
    onDataPropChange: (val: any, key: string) => void,
}

interface Step1Type {
    title: string,
    icon: string,
    preview: React.ReactChild,
    disabled?: boolean
}

export default function Step1(props: Props) {
    const activeType = props.activeElement.getType();
    const setActiveType = (val: number) => {
        props.onDataPropChange(val, 'type');
    }

    const types: Step1Type[] = [
        {title: 'Sales', icon: 'icon-dollar', preview: <SalesPreview/>},
        {title: 'New users', icon: 'icon-user-plus', preview: <NewUsersPreview/>, disabled: true},
        {title: 'Visitors', icon: 'icon-users', preview: <VisitorsPreview/>, disabled: true},
        {title: 'Subscribers', icon: 'icon-layers', preview: <SubscribersPreview/>, disabled: true},
        // {title: 'Reviews', icon: 'icon-thumb-up', preview: <ReviewsPreview/>}
    ]

    const validateName = () => {
        return new Promise((resolve) => {
            if (!props.activeElement.getTitle()) {
                toast.error("Please add name");
                return;
            }

            const query = new Parse.Query(BFNotificationsEntity);
            query.equalTo("title", props.activeElement.getTitle());

            query.find().then((res: BFNotificationsEntity[]) => {
                const f = res.filter((item) => item.id !== props.activeElement.id);
                if (f.length === 0) {
                    resolve();
                    return;
                }
                toast.error("Notification with same title exists");
            });
        });
    }

    return (
        <StepFormWrapper>
            <h2>Enter notification Title</h2>
            <div className="form-wide">
                <Field title={"Notification title"} fieldKey={'title'} val={props.activeElement.getTitle()}
                       onChange={(key: string, val: string) => props.onDataPropChange(val, key)}
                       required={true}
                       placeholder={"Enter title for notification"}
                />
            </div>
            <h2>Choose Notification type</h2>
            <div className="tabs-a">
                <nav>
                    <ul>
                        {types.map((item: Step1Type, index: number) => {
                            const itemId = "r-step-" + index;
                            return (
                                <li key={'l-step-' + index} className={item.disabled ? "f-disabled" : ""}
                                    title={item.disabled ? "Coming soon" : item.title}>
                                    <input type="radio" id={itemId} name={itemId}
                                           onChange={() => {
                                           }}
                                           disabled={item.disabled}
                                           onClick={() => {
                                               setActiveType(index)
                                           }} checked={activeType === index}/>
                                    <label htmlFor={itemId}><i className={item.icon}></i> {item.title}</label>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
                <div>
                    {types[activeType].preview}
                </div>
            </div>
            <StepSubmit activeStep={props.activeStep} setActiveStep={props.setActiveStep}
                        activeElement={props.activeElement}
                        validate={validateName}
            />
        </StepFormWrapper>
    )
}
