import React, {Fragment} from "react";
import {BFNotificationsEntity} from "../../../db";

interface Props {
    activeStep: number,
    setActiveStep: (val: number) => void,
    activeElement: BFNotificationsEntity,
}

export default function Steps(props: Props) {
    const {activeStep, setActiveStep, activeElement} = props;
    const steps = [
        'Type',
        'Message & Products',
        'Display',
        'Customize',
        'Activate'
    ];

    return (
        <Fragment>
            <ul className="list-steps">
                {steps.map((step: string, index: number) => {
                    const isActive = index <= activeElement.getLastStep();
                    const className = activeStep === index ? 'active' : '';
                    return (
                        <li className={className} key={"step-" + index}>{isActive ?
                            <a href={"/step-" + index} onClick={(e) => {
                                e.preventDefault();
                                setActiveStep(index);
                            }}>{step}</a> : step}</li>
                    )
                })}
            </ul>
            <h1 className="mobile-only s14 text-center">{activeStep + 1}. {steps[activeStep]}</h1>
        </Fragment>
    )
}