import React from "react";

import UserA from "../../../assets/images/content/user-a.png"
import AppMenu from "./AppMenu";
import {useSelector} from "react-redux";
import {IStore} from "../../../types/redux.types";

const Parse = require('parse');

export default function AppHeader() {
    const reloadUser = useSelector((state : IStore) => state.Reload.user);
    const currentUser = Parse.User.current();

    const avatar = currentUser.get('avatar')?currentUser.get('avatar').url():UserA;

    return (
        <header id="top">
            <p id="logo"><a href="./">Blackfomo</a></p>
            <nav id="skip">
                <ul>
                    <li><a href="#nav">Skip to navigation (n)</a></li>
                    <li><a href="#content">Skip to content (c)</a></li>
                </ul>
            </nav>
            <p className="scheme-user">
                <img src={avatar} alt="" width="48" height="48"/>{currentUser.get('fullName')} <span
                className="email">{currentUser.get('email')}</span></p>
            <p>Manage</p>
            <AppMenu/>
        </header>
    )
}
