import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {storeSetAuth} from "./redux/actions";

interface Props {
    children: any,
}

const Parse = require('parse');


export default function AppAuth(props: Props) {
    let currentUser = Parse.User.current();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(storeSetAuth(currentUser !== null))
    }, [currentUser]);

    return props.children
}
