const Parse = require('parse');

export class BFNotificationsProductEntity extends Parse.Object {
    constructor() {
        super('BFNotificationsProductEntity');
    }

    setDefaults = () => {
        this.set('name', '');
        this.set('gender', '');
        this.set('url', '');
    }
}
