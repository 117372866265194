import React from "react";
import {BFNotificationsEntity} from "../../../../../db";
import {toast} from "react-toastify";

interface Props {

    activeElement: BFNotificationsEntity,
}

export default function CopyBlock(props: Props) {
    const text = `<script type="text/javascript" src="${props.activeElement.getScriptUrl()}"></script>`;


    const doCopy = (e: React.MouseEvent) => {
        e.preventDefault();

        navigator.clipboard.writeText(text).then(r => {
            toast.success("The text has been copied to the clipboard");
        });
    }

    return (
        <div className="form-wide">
            <p>
                <label htmlFor="fwa">Please include this script into page:</label>
                <input type="text" id="fwa" name="fwa"
                       value={text}
                       readOnly/>
                <a href="/copy" onClick={doCopy} className="btn">Copy</a>
            </p>
        </div>
    )
}
