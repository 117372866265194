import {IAuthReducer, ISetAuthAction, SET_AUTH} from "../../types/redux.types";

const InitialState: IAuthReducer = {
    isAuth: false,
};

export default function Auth(state = InitialState, action: ISetAuthAction) {
    switch (action.type) {
        case SET_AUTH:
            return {...state, isAuth: action.payload};
        default:
            return state
    }
}
