import React, {useEffect, useState} from "react";

interface Props {
    title: string,
    text: string,
    children: any,
}

export default function Tooltip(props: Props) {
    const [isOpen, setIsOpen] = useState(false);

    const eventHandler = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        document.body.addEventListener('click', eventHandler);

        return () => {
            document.body.removeEventListener('click', eventHandler);
        }
    }, []);

    return (
        <span className="tooltip-outer">{props.children}
            <span className={"tooltip" + (isOpen ? ' toggle' : '')}>
                <span className="inner">
                    <span className="strong">{props.title}</span>
                    {props.text}
                </span>
                <a href="/open" onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}>&nbsp;</a>

            </span>
        </span>
    )
}