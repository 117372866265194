import React from "react";
import UserB from "../../../../../assets/images/content/user-b.png";
import LvFlag from "../../../../../assets/images/content/flags/lv.png";
export default function SalesPreview() {
    return (
        <div>
            <p className="scheme-icon"><i className="icon-thumb-up"></i> Sales - notification
                preview
            </p>
            {/*<div className="module-user">*/}
            {/*    <h3><span>Subscribed to:</span> Blackfomo Plus</h3>*/}
            {/*    <figure><img src={UserB} alt="Placeholder" width="88"*/}
            {/*                 height="88"/>*/}
            {/*    </figure>*/}
            {/*    <p><span><img src={LvFlag} alt="Placeholder" width="18"*/}
            {/*                  height="12"/> Latvia, Riga</span> Viktor Pixel</p>*/}
            {/*    <ul>*/}
            {/*        <li>3 hours ago</li>*/}
            {/*        <li className="overlay-d"><i className="icon-check-circle"></i> Verified</li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
            <div className="module-user">
                <figure><img src={UserB} alt="" width="88" height="88"/></figure>
                <p><span><img src={LvFlag} alt="" width="18"
                              height="12"/> Latvia, Riga</span> Viktor Pixel subscribed:<br/> <span className="strong">Blackfomo Plus</span>
                </p>
                <ul>
                    <li>3 hours ago</li>
                    <li className="overlay-d"><i className="icon-check-circle"></i> Verified</li>
                </ul>
            </div>
        </div>
)
}