import React, {useEffect, useState} from "react";

import {useHistory} from 'react-router-dom'
import Field from "../../form/Field";
import {useDispatch} from "react-redux";
import {storeSetAuth} from "../../redux/actions";
import {toast} from "react-toastify";

const Parse = require('parse');

export default function LoginPage() {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    const goToSignup = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        history.push('/signup');
    }

    const addSignupClass = () => {
        document.getElementsByTagName('html')[0].classList.add('theme-signup');
    }
    const removeSignupClass = () => {
        document.getElementsByTagName('html')[0].classList.remove('theme-signup');
    }
    useEffect(() => {
        addSignupClass()
    }, []);

    const doLogin = () => {
        Parse.User.logIn(email, password).then((res: any) => {
            // console.log('res', res);
            dispatch(storeSetAuth(true));
            removeSignupClass();
            history.push('/');
        }).catch((e: any) => {
            // console.log('login error', e);
            toast.error(e.message);
        })
    }

    const doPasswordReset = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/password-reset');
    }

    return (
        <main id="content">
            <form method="post" className="form-signup">
                <header>
                    <h1>Welcome back!</h1>
                    <p>Sign up and improve your business</p>
                </header>
                <Field title={"Enter email address"} fieldKey={"email"} val={email}
                       onChange={(key: string, val: string) => setEmail(val)}
                       required={true}/>

                <Field title={"Enter password"}
                       fieldKey={"password"}
                       val={password}
                       onChange={(key: string, val: string) => setPassword(val)}
                       type={"password"}
                       required={true}
                >
                    <a href="/password-reset" onClick={doPasswordReset}>Reset password</a>
                </Field>

                <p>
                    <button type="button" onClick={doLogin}>Log in</button>
                </p>
                <footer>
                    <h2>or</h2>
                    <p className="link-btn"><a href="./" className="gl">Continue with Google</a></p>
                    <p>New user? <a href="/signup" onClick={goToSignup}>Sign up</a></p>
                </footer>
            </form>
        </main>
    )
}
