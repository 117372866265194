import React, {useEffect, useState} from "react";
import Steps from "./Steps";
import AppLayout from "../../app/AppLayout";
import Step1 from "./content/Step1";
import Step2 from "./content/Step2";
import Step3 from "./content/Step3";
import Step4 from "./content/Step4";
import Step5 from "./content/Step5";
import {useHistory, useParams} from 'react-router-dom';
import {BFNotificationsEntity} from "../../../db";

const Parse = require('parse');

export default function StepsPage() {
    const {id} = useParams();

    const newEl = new BFNotificationsEntity();
    newEl.setDefaults();

    const [activeElement, setActiveElement] = useState<BFNotificationsEntity>(newEl);

    const onDataPropChange = (val: any, key: string) => {
        const newEl = activeElement.clone();
        newEl.id = activeElement.id;
        newEl.set(key, val);

        setActiveElement(newEl);
    }

    const getData = () => {
        const query = new Parse.Query(BFNotificationsEntity);
        query.include('products');
        query.include('matchUrls');
        query.include('notMatchUrls');
        query.get(id)
            .then((item: BFNotificationsEntity) => {
                setActiveElement(item);
            }, (error: any) => {
            });
    }
    useEffect(getData, [id]);

    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);

    const content = () => {
        switch (activeStep) {
            case 0:
                return <Step1 activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              activeElement={activeElement}
                              onDataPropChange={onDataPropChange}
                />;
            case 1:
                return <Step2 activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              activeElement={activeElement}
                              onDataPropChange={onDataPropChange}
                />;
            case 2:
                return <Step3 activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              activeElement={activeElement}
                              onDataPropChange={onDataPropChange}
                />;
            case 3:
                return <Step4 activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              activeElement={activeElement}
                              onDataPropChange={onDataPropChange}
                />;
            case 4:
                return <Step5 activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              activeElement={activeElement}
                              onDataPropChange={onDataPropChange}
                />;
        }
    }

    useEffect(() => {
        if (activeStep === -1) {
            history.push('/notifications');
        }
    }, [activeStep, history]);

    return (
        <AppLayout>
            <main id="content">
                <Steps activeElement={activeElement} activeStep={activeStep} setActiveStep={setActiveStep}/>
                {content()}
            </main>
        </AppLayout>
    )
}
