import React from "react";

export default function SubscribersPreview() {
    return (
        <div>
            <p className="scheme-icon"><i className="icon-thumb-up"></i> Subscribers - notification
                preview</p>
            <p className="scheme-notification"><span>3 hours ago</span> A developer from the United
                States just signed up for Blackfomo!</p>

        </div>
    )
}
